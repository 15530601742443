<template>
    <div class="waypoint" v-if="content!==null">
        <div class="waypoint-distance">
            <span v-if="false">{{ distance }}</span>
            <span v-if="showDot"><img class="dot" src="/assets/icons/basic_dot.png"></span>
        </div>
        <div class="waypoint-card">
            <div class="waypoint-card-content">
                <div class="card-img" :style="backgroundImage">
                </div>
                <div class="card-body">
                    <h3 class="title">{{ title }}</h3>
                    <ul class="card-detail__features">
                        
                        <li v-if="isset(adresse)" class="adresse mr5">
                            <i class="material-icons material-icons-round" aria-hidden="true">place</i>
                            <span>{{adresse}}</span>
                        </li>
                        <li class="appointments" :class="isCurrentlyActiveClass" v-if="appointments">
                            <Appointments :appointments="appointments"/>
                        </li>
                    </ul>
                    
                    <div class="text mb30">
                        <div class="text-list" v-if="description" v-html="getContent()"></div>
                    </div>
                    
                    <div class="audio-walk-section" v-if="audioPath!==null">
                        <div class="d-flex">
                            <i class="material-icons" aria-hidden="true">headphones</i>
                            <div class="text">
                                <p class="header">{{ audioTitle }}</p>
                            </div>
                        </div>
                        <audio-player :file="audioPath"></audio-player>
                    </div>
                    <router-link v-if="content.content_type_name=='sehenswrdigkeit'" :title="linkToTitle" class="btn btn-sm mt20 more-info float-right" :to="{ name: 'Attraktion', params: {id: content.id } }">
                        Mehr Infos
                    </router-link>
                    
                    <router-link v-if="content.content_type_name=='angebot'" :title="linkToTitle" class="btn btn-sm mt20 more-info float-right" :to="{ name: 'Veranstaltung', params: {id: content.id } }">
                        Mehr Infos
                    </router-link>
                    
                    <router-link v-else-if="content.content_type_name=='institution'" :title="linkToTitle" class="btn btn-sm mt20 more-info float-right" :to="{ name: 'Anbieterprofil', params: {id: content.institution_id } }">
                        Mehr Infos
                    </router-link>
                    
                    <router-link v-else-if="content.content_type_name=='geschichte'" :title="linkToTitle" class="btn btn-sm mt20 more-info float-right" :to="{ name: 'Story', params: {id: content.id } }">
                        Mehr Infos
                    </router-link>
                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { smartSubStr, getMonth, getWeekday, formatTime, getFieldValues, getNextAppointment, isOneOfTheAppointmentsNow, getPlaceholderImage, json_decode, isset } from '@/utils/helpers';

export default {
    name: "WayPoint",
    components: {
        AudioPlayer: () =>import('@/components/controls/AudioPlayer.vue'),
        Appointments: () =>import('@/components/details/appointments.vue'),
    },
    props: {
        content:{
            required: true,
            default: () => ({})
        },
        distance:{
            type:String,
            default: '',
            required: true
        },
        showDot:{
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            maxLength: 150,
            showMore: false,
            image: null,
        }
    },
    watch: {
        content: {
            handler: function(images) {
                if(this.content.content_type_name !== 'geschichte'){
                    var pictures = getFieldValues(this.content,'teaser-bild');
                }
                else{
                    if(this.content.images !==null){
                        var pictures = this.content.images;
                    }
                }
                if(pictures != null){
                    if(Array.isArray(pictures)){
                        this.image = pictures;
                    }
                    else{
                        this.image = [pictures];
                    }
                }
            },
            deep: true,
            immediate: true
        },
    },
    computed:{
        linkToTitle(){
            return "Mehr Informationen zu: "+this.content.title;
        },
        btnShowMore() {
            if (this.description.length > this.maxLength) {
                return true;
            }
            return false;
        },
        title(){
            if(this.content!=null){
                return this.content.title;
            }
            return "";
        },
        titelBild(){
            return getFieldValues(this.content,'titelbild');
        },
        storyParentId(){
            var id = getFieldValues(this.content,'sehenswrdigkeit');
            
            if(isset(id)){
                return id;
            }
            return null;
        },
        logo(){
            var logo = getFieldValues(this.content,'logo');
            if(logo != null){
                return this.$backendUrl+logo;
            }
            return null;
        },
        addressObj(){
            var adresse = getFieldValues(this.content,'adresse');
            return adresse;
        },
        adresse(){
            if(this.addressObj!=null && isset(this.addressObj.street) && isset(this.addressObj.zipcode) && isset(this.addressObj.city)){
                return this.addressObj.street+', '+this.addressObj.zipcode+' '+this.addressObj.city;
            }
            else if (this.addressObj!=null && isset(this.addressObj.zipcode) && isset(this.addressObj.city)){
                return this.addressObj.zipcode+' '+this.addressObj.city;
            }
            else if(this.addressObj!=null && isset(this.addressObj.city)){
                return this.addressObj.city;
            }
            return '';
        },
        audioWalk(){
            if(this.content!==null){
                return getFieldValues(this.content,'audiowalk');
            }
            return null;
        },
        audioTitle(){
            if(this.audioWalk!==null){
                return  'Audio: ' + getFieldValues(this.audioWalk,'title');
            }
            return 'Audiowalk'
        },
        audioPath(){
            if(this.audioWalk!==null){
                var audiofile = getFieldValues(this.audioWalk,'audio-datei');
                if(isset(audiofile)){
                    audiofile = json_decode(audiofile);
                    return this.$backendUrl + audiofile.path;
                }
            }
            return null
        },  
        
        backgroundImage(){
            return 'background-image: url("' + this.teaserImage + '")';
        },
        description(){
            var desc = getFieldValues(this.content,'einleitung');
            return desc!=null?desc:'';
        },
        teaserImage(){
            var image = this.titelBild;
            if(image!=null){
                image = json_decode(image);
                if(typeof image === "object"){
                    return this.$backendUrl+image.path;
                }
                else{
                    return this.$backendUrl+image;
                }
            }
            
            /*we'll need an alternative if there is no image*/
            return this.getPlaceholderImage(this.category);
        },
        appointments(){
            return getFieldValues(this.content,'appointments');
        },
        nextAppointment(){
            return getNextAppointment(this.appointments);
        },
        nextAppointmentWday(){
            if(this.nextAppointment!=null && this.nextAppointment.wday!=null){
                var day = getWeekday(this.nextAppointment.wday);
                return day.substring(0,2);
            }
            return null;
        },
        nextAppointmentDate(){
            if(this.nextAppointment!=null && this.nextAppointment.date!=null){
                var date = new Date(this.nextAppointment.date);
                return date.getDate();
            }
            return null;
        },
        nextAppointmentStartTime(){
            if(this.nextAppointment!=null && this.nextAppointment.time!=null && this.nextAppointment.time.time_from!=null){
                var time = this.nextAppointment.time.time_from;
                return formatTime(time);
            }
            return null;
        },
        nextAppointmentMonthAndYear(){
            if(this.nextAppointment!=null && this.nextAppointment.date!=null){
                var date = new Date(this.nextAppointment.date);
                var month = getMonth(date.getMonth()+1);
                var year = date.getFullYear();
                return month.substring(0,3) + '. '+year;
            }
            return null;
        },
        isCurrentlyActive(){           
            if(this.appointments!=null){
                return isOneOfTheAppointmentsNow(this.appointments);
            }
            return false;
        },
        isCurrentlyActiveClass: function() {
            if (this.isCurrentlyActive){
                return 'highlight';
            }
            return '';
        },
    },
    methods:{
        getMonth,
        getWeekday, 
        formatTime, 
        getFieldValues, 
        getNextAppointment, 
        isOneOfTheAppointmentsNow, 
        getPlaceholderImage,
        smartSubStr,
        json_decode,
        isset,
        getContent(){
            return this.description
        },
        limitedString() {
            if (this.description.length > this.maxLength) {
                return smartSubStr(this.description, this.maxLength) + ' ...';
            }
            
            return this.description;
        },
    }
}
</script>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

.waypoint {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    margin-bottom: 30px;
    width: 100%;
    

    border-radius: 5px;
    border-right: 20px solid #009FE3;
    
    .audio-walk-section{
        
        .text{
            margin-left: 10px;
            display: inline-block;
            vertical-align: middle;
        }
        
        .header{
            font-family: 'elliot-bold', sans-serif;
            font-weight: 700;
        }
        
        i.material-icons{
            font-size: 25px;
            vertical-align: middle;
        }
    }
    
    .btn-showmore {
        display: flex;
        justify-content: center;
        vertical-align: middle;
        align-items: center;
        text-transform: capitalize;
        color: $darkGray;
        margin-top: 5px;
        margin-bottom: 15px;
        
        i.material-icons{
            position: relative;
        }
        
        &:hover,
        &:active,
        &:focus {
            text-decoration: none;
        }
    }
    
    
    &-distance {
        position: relative;
        width: 60px;
        min-width: 60px;
        margin-right: 30px;
        
        @media(max-width:991px){
            display: none;
        }
        
        span {
            position: relative;
            top: 30px;
            background: #fff;
            display: inline-block;
            text-align: center;
            width: 62px;
            z-index: 2;
            
            @media (max-width: 500px) {
                width: 40px;
            }
        }
        
        .dot {
            width: 10px;
            height: 10px;
        }
        
        &:after {
            content: '';
            position: absolute;
            top: -30px;
            left: 30px;
            width: 3px;
            background: $secondary;
            height: calc(100% + 30px);
            z-index: 1;
            
            @media (max-width: 500px) {
                left: 15px;
            }
        }
        
        @media (max-width: 500px) {
            width: 40px;
            max-width: 40px;
            margin-right: 10px;
        }
    }
    
    &-card {
        position: relative;
        display: block;
        max-width: calc(100% - 90px);
        width: 100%;
        border-radius: 5px;
        border-bottom-left-radius: 50px;
        border: none;
        box-shadow: -5px 5px 15px 0px rgba($color: $primary, $alpha: 0.2);
        
        @media(max-width: 991px) {
            max-width: 100%;
            box-shadow: 0px 5px 15px 0px rgba($color: $primary, $alpha: 0.2);
        }
        
        .card-img {
            max-width: 250px;
            border-bottom-left-radius: 50px;
            position: relative;
            min-height: 140px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
            
            @media(max-width: 991px){
                width: 100%;
                max-width: 100%;
                border-bottom-left-radius: 0;
            }
            
            img {
                width: 100%;
            }
        }
        
        &-content{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            
            @media(max-width: 991px){
                display: block;
            }
        }
        
        .card-body {
            padding: 25px;
            .title {
                font-size: 14px;
                margin-bottom: 5px;
            }
            
            .location {
                margin-bottom: 10px;
                display: inline-flex;
                vertical-align: middle;
                align-items: center;
                
                i {
                    color: rgba($color: #686B6F, $alpha: 0.5);
                    margin-right: 10px;
                }
                
                span {
                    display: inline-block;
                    color: rgba($color: #1F2041, $alpha: 0.5);
                }
            }
            
            .appointments{
                @media (max-width: 600px){
                    display:none;
                }
            }
            
            .more-info {
                @media(max-width:991px){
                    margin-bottom: 15px;
                }
            }
            
            .text-list {
                color: rgba($color: #1F2041, $alpha: 0.8);
                font-size: 14px;
                line-height: 24px;
                
                @media (max-width: 500px) {
                    font-size: 13px;
                    line-height: 22px;
                }
            }
            
            @media (max-width: 500px) {
                padding: 15px;
            }
        }
        
        .card-detail__features {
            display: flex;
            
            @media (max-width: 600px) {
                display: block;
                text-align: center;
            }
            
            .adresse {
                padding: 13px 0;
                align-items: normal;
            }
            
            li {
                flex: 1;
                display: inline-flex;
                vertical-align: middle;
                align-items: center;
                
                @media(max-width:991px){
                    display: flex;
                }
                
                i, img {
                    margin-right: 10px;
                }
                
                img {
                    display: inline-block;
                    max-height: 24px;
                }
                
                &.highlight {
                    color: $success !important;
                    
                    span {
                        font-weight: 600;
                    }
                }
            }
        }
    }
}
</style>
